import React from 'react'
import styled from 'styled-components'
import { ReactComponent as LockSVG } from 'icons-svg/icons/lock_svg.svg';
import { getMsalUserInfo, getMsalLogout } from 'authConfig';

const APP_ENV = process.env.REACT_APP_ENV.toString().toLowerCase()

const StyledInvalidAccess = styled.div`
	width: 100%; height: 100%; background-color: black;
	display: flex; flex-direction: column;
	justify-content: center; align-items: center;
	color: white;
	font-size: 16px;
	text-align: center;
	li {text-align: left;}
	a {
		color: white;
		text-decoration: underline;
		cursor: pointer;
	}
`



const getMessage = ({level}) => {
	const { name } = getMsalUserInfo();
	//const { given_name, family_name } = profile;
	switch(level) {
		case "tenant": return (
			<p>
				{`Hello, ${name}`}
				<br />
				Looks like you have logged in using unauthorized Active Directory tenant.
				<br />
				Please use a trusted Azure Active Directory tenant to log in.
				<br />
				<a onClick={getMsalLogout}>Logout</a>
			</p>
		)
		case "user": return (
			<p>
				{`Hello, ${name}, Welcome to OTIS ONE Expert Portal(OXP)!`}
				<br />
				Looks like you do not have the access rights for OXP.
				<br />
				<br />
				To request access, please contact Otis ONE Country Admin:
				<ul>
					<li>First Name </li>
					<li>Last Name </li>
					<li>Azure ID (example Joshua.smith@rc.ap.otis.utc.com)</li>
					<li>Countries for which access is needed </li>
					<li>Role (Expert, OtisLine or Mechanic)</li>
				</ul>
				<br />
				<a onClick={getMsalLogout}>Logout</a>
			</p>
		);
		case "country": return (
			<p>
				{`Hello, ${name}, Welcome to OTIS ONE Expert Portal(OXP)!`}
				<br />
				Looks like you do not have the access rights for this country.
				<br />
				<br />
				To request access, please contact Otis ONE Country Admin:
				<ul>
					<li>First Name </li>
					<li>Last Name </li>
					<li>Azure ID (example Joshua.smith@rc.ap.otis.utc.com)</li>
					<li>Countries for which access is needed </li>
					<li>Role (Expert, OtisLine or Mechanic)</li>
				</ul>
				<br />
				<a onClick={getMsalLogout}>Logout</a>
			</p>
		);
	}
}
export function InvalidAccess({level}) {
	return (
		<StyledInvalidAccess>
			<LockSVG />
			<br />
			{getMessage({level})}
		</StyledInvalidAccess>
	)
}