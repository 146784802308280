const CustomPropertiesMapping = (properties) => {
	const { Role = "", UPN = "" , DefaultCountry = ""} = properties || {};
	
	const CustomPropertiesObj = {
		OXPUserType: Role,
		// OXPUserDetails: UPN,
		OXPCountry: DefaultCountry,
	};
	return CustomPropertiesObj;
};

export default CustomPropertiesMapping;
