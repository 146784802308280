export const validTenants = [
    "26d923a9-6304-4745-8b2d-95793e9324e4",
    "937d8a39-dd46-4ed8-802b-2c44da18c350",
    "03afca2b-f47f-4d0b-9a25-d464aff5d351", //otis elevator
    "f7cf6ab3-a154-48b5-b860-cfd99a40f9a8",
    "a384ed05-4d66-4ce7-937d-3dab0fb2d8d4",
    "2bae096f-a0e3-400c-8c0a-c0ac0a5e7fde",
    "9c7d751b-b1a4-4c4a-9343-12b2814ae031"
]

export const isValidTenant = ({tid}) => validTenants.some(id => id === tid)