import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
//react
import React from 'react'
import ReactDOM from "react-dom";
//css
import 'bootstrap/dist/css/bootstrap.css';
import 'Theme/index.css';
//MSAL Imports
import { msalInstance, runWithMsal } from 'authConfig';
import { isValidTenant } from 'constants/tenants';
import { InvalidAccess } from 'components/common/InvalidAccess';
import { downloadAndSetup } from 'components/AppInsights/AppInsightsTrack';

//jsonwebtoken workaround
window.process = {};
downloadAndSetup(process.env.REACT_APP_INSIGHTS_KEY)
export const RenderDOM = ({ component }) => ReactDOM.render(component, document.getElementById("root"))

runWithMsal(msalInstance, ()=>{
	const profile = msalInstance.getAllAccounts()[0];
	const { idTokenClaims } = profile;
	const { tid } = idTokenClaims;
	//const { tenantId } = profile
	if(isValidTenant({tid})){
		localStorage.setItem('user_profile', JSON.stringify(profile));
		import('./indexApp.js');
	}
	else {
		RenderDOM({ component: <InvalidAccess level="tenant" /> });
	}
})