// import { getAppInsights, ai } from "components/AppInsights/TelemetryService";
import { AppInsights } from "applicationinsights-js";
import CustomPropertiesMapping from "./CustomProperties";

const trackPageView = (page, properties) => {
	const Location = window.location.href;
	if (Location.includes("localhost") === false) {
		// debugger;
		AppInsights.trackPageView(page, page, CustomPropertiesMapping(properties))
	}
}
const trackEvent = (page, properties) => {
	const Location = window.location.href;
	if (Location.includes("localhost") === false) {
		// debugger;
		AppInsights.trackEvent(page, CustomPropertiesMapping(properties))
	}
}
const startTrackPage = (page, properties) => {
	const Location = window.location.href;
	if (Location.includes("localhost") === false) {
		// debugger;
		AppInsights.startTrackPage(page, null, CustomPropertiesMapping(properties))
	}
}
const stopTrackPage = (page, properties) => {
	const Location = window.location.href;
	if (Location.includes("localhost") === false) {
		// debugger;
		AppInsights.stopTrackPage(page, null, CustomPropertiesMapping(properties))
	}
}

const downloadAndSetup = (env) => {
	
	AppInsights.downloadAndSetup({
		instrumentationKey: env,
		disableCookiesUsage: true
	})
}

export { trackEvent, startTrackPage, stopTrackPage, trackPageView, downloadAndSetup  };
